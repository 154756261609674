.footer {
    background: #131319;
    padding-bottom: 30px;
    text-align: center;
}

.footer-box {
    padding-left: 20px;
    padding-right: 20px;
    display: inline-grid;
}

.footer-boxes {
    padding-top: 15px;
}

.secret-shiba-word-logo {
    width: 160px;
    padding-top: 15px;
}

.footer-title {
    font: 18px 'SukarBold';
    color: white;
    letter-spacing: 1px;
    padding-bottom: 4px;
}
.footer-text {
    font: 16px 'SukarRegular';
    color: #cccccc;
    letter-spacing: 1px;
    padding: 2px;
    text-decoration: none;
}