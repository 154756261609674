@font-face {
    font-family: 'SukarRegular';
    src: local('SukarRegular'), url(./fonts/SukarRegular.otf) format('opentype');
}
@font-face {
    font-family: 'SukarBold';
    src: local('SukarBold'), url(./fonts/SukarBold.otf) format('opentype');
}
@font-face {
    font-family: 'SukarBlack';
    src: local('SukarBlack'), url(./fonts/SukarBlack.otf) format('opentype');
}

#root {
    margin: -8px;
}